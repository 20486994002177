<template>
  <v-app>
    <v-container class="my-bid__container mt-5" fluid>
      <v-row class="bid-cards__node">
        <v-row>
          <v-col cols="12" class="filter-section">
            <v-div class="align-center filter-btns">
              <ul>
                <li>
                  <button
                    @click="toggleExpanded('Current')"
                    v-bind:class="{ active: activeTab === 'Current' }"
                  >
                    Current Bids
                  </button>
                </li>
                <li>
                  <button
                    @click="toggleExpanded('Archived')"
                    v-bind:class="{ active: activeTab === 'Archived' }"
                  >
                    Archived Bids
                  </button>
                </li>
              </ul>
            </v-div>
            <v-div class="filter-type">
              <fieldset class="bid-filter">
                <legend>Bidding Mode</legend>
                <v-selector @update:modelValue="handleUpdate" v-model="type" :options="['Open','Hybrid']" :clearable="false"></v-selector>
              </fieldset>
            </v-div>
          </v-col>
        </v-row>

        <v-row class="card-list mt-0" v-if="bids.length > 0">
          <template v-if="bids.length > 0">
            <v-col
              v-for="(bid, index) in bids"
              :key="index"
              cols="12"
              xs="12"
              sm="12"
              md="4"
              lg="3"
              xl="3"
              class="card-item__box"
            >
              <v-card class="pa-0 pb-3">
                <v-card-body @click="() => redirectToDetails(bid)">
                  <v-col col="12" class="item-img px-0 py-0">
                    <img
                      :src="bid.lotImage ? bid.lotImage : lotImage"
                      @error="replaceLotByDefault"
                      eager
                      contain
                    />
                    <span>{{ bid.offPercentage }}% OFF</span>
                  </v-col>
                  <v-col col="12" class="item-desc" sm="12" md="12" lg="12">
                    <a href="#">
                      <p>
                        {{bid.title}}
                        <v-tooltip
                          v-if="!isMobile()"
                          activator="parent"
                          location="auto"
                        >{{bid.title}}</v-tooltip>
                      </p>
                      <v-p class="location">
                        <v-icon
                          size="small"
                          icon="mdi-map-marker-outline"
                          color="#1962D5"
                        ></v-icon>
                        {{ bid.delivery }}
                      </v-p>
                    </a>
                  </v-col>

                  <v-col
                    col="12"
                    class="price-slider pb-0 pt-0"
                    xs="12"
                    md="12"
                    lg="12"
                  >
                    <div class="d-flex justify-space-between bid-color__node">
                      <div class="text-caption d-flex align-center">
                        <span v-if="type === 'Open'" class="bg-dark-blue"></span> <p v-if="type === 'Open'">Current Bid:</p>
                        <b v-if="type === 'Open'">&nbsp;&nbsp;&#8377;{{ formatCurrency(bid.currentBid) }}</b>
                      </div>

                      <div class="text-caption">
                        Quantity: {{ bid.quantity }}
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    col="12"
                    class="price-slider pb-0 pt-0"
                    xs="12"
                    md="12"
                    lg="12"
                  >
                    <div class="d-flex justify-space-between bid-color__node">
                      <div class="text-caption d-flex align-center" v-if="activeTab === 'Current'">
                        <span class="bg-dark-blue"> </span>My Bid:
                        <b v-bind:class="bid.color">&nbsp;&nbsp;&#8377;{{ formatCurrency(bid.myBid) }}</b>
                        <b
                          v-bind:class="bid.color"
                        >
                          &nbsp;{{ bid.rank && `${bid.rank}` }}
                        </b>
                      </div>
                      <div class="text-caption d-flex align-center" v-if="activeTab === 'Archived'">
                        <span class="bg-dark-blue"> </span>My Bid:
                        <b v-bind:class="bid.bidStatus">&nbsp;&nbsp;&#8377;{{ formatCurrency(bid.myBid) }}</b>
                        <b
                          v-bind:class="bid.bidStatus"
                        >
                          &nbsp;{{ bid.bidStatus && `(${bid.bidStatus })` }}
                        </b>
                      </div> 
                    </div>
                  </v-col>
                  <v-col
                    col="12"
                    class="deadline-timer__desc pb-0"
                    xs="12"
                    md="12"
                    lg="12"
                  >
                    <v-row class="d-flex">
                      <v-col col="6">
                        <div class="price d-flex align-center">
                          <p><span>Lot</span><br />MRP<sup>*</sup></p>
                          <p>&nbsp;&nbsp;₹{{ formatCurrency(bid.mrp) }}</p>
                        </div>
                      </v-col>
                      <v-col
                        col="6"
                        class="timer d-flex justify-space-between align-center"
                      >
                        <CountDown
                          :remainingTime="bid.bidRemainingTime"
                          :endDate="bid.endDate"
                          :status="bid.status"
                        ></CountDown>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-card-body>

                <v-card-actions class="pb-0 d-none">
                  <v-btn color="blue-darken-1 flex-grow-1" variant="flat">
                    Bid @{{ bid.bidPrice }} (Save 97.07%)
                  </v-btn>
                </v-card-actions>
                <v-card-actions class="d-none">
                  <v-btn color="blue-darken-1 flex-grow-1" variant="outlined">
                    Buy @{{ bid.buyPrice }} (Save 87.00%)
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-pagination
                @update:modelValue="handlePageUpdate"
                v-model="activePage"
                :total-visible="6"
                :length="getAllPages"
              ></v-pagination>
            </v-col>
          </template>
        </v-row>
      </v-row>
      <template v-if="bids.length < 1">
        <v-col class="text-center"> No Data Available. </v-col>
      </template>
    </v-container>
  </v-app>
</template>

<script>
import app from "./index.js";

export default app;
</script>
