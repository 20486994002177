<template>
    <div class="show-notification" v-if="show">
        <div class="danger">
            <!-- <p>{{ message }}</p> -->
            <v-alert :text="message" :type="type"></v-alert>
        </div>
    </div>
</template>

<script setup>
import { computed,  onUpdated } from 'vue';
import { useAlertStore } from '../../stores/alert.store';

const alertStore = useAlertStore()

const type = computed(() => alertStore.type)
const message = computed(() => alertStore.message)
const show = computed(() => alertStore.show)

onUpdated(()=>{
    if (show.value) {
        setTimeout(() => {
            alertStore.setAlertMessage("", "", false)
        }, 10000)
    }
})
</script>