<template>
  <section>
    <v-container>
      <v-card class="mx-auto pa-5 about-container box-container">
        <v-card-text>
          <v-row class="header">
            <v-col cols="12" class="text-center">
              GREEN ENABLED IT SOLUTIONS PRIVATE LIMITED
            </v-col>
            <v-col cols="12" class="text-center"> PRIVACY POLICY </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p>
                In the course of a user / subscriber / visitor accessing /
                visiting / using the website [wwww.b4traders.com] and buyer app
                mobile application (together hereinafter referred to as the
                “Website") and its related sites, services, tools and software,
                M/s Green Enabled IT Solutions Private Limited, a company
                incorporated under Companies Act, 2013 having its registered
                office at Bansashankari, Bangalore - 560 085, Karnataka, India
                (hereinafter referred to as “Company”), may become privy to the
                personal information of the user / subscribers, including
                information that is of a confidential nature.
              </p>
              <p>
                This Privacy Policy is an integral part of the Terms of Use as
                mentioned on the Website. This Privacy Policy is incorporated
                into the Terms of Use by reference.
              </p>
              <p>
                We value the trust you place in us. That's why we insist upon
                the highest standards for secure communication and customer
                information privacy. Please read the following statement to
                learn about our information gathering and dissemination
                practices. The Privacy Policy specified herein is subject to
                change at any time without notice. To make sure you are aware of
                any changes, please review this policy periodically. By visiting
                the Website and / or using its related sites, services, tools
                and software you agree to be bound by the terms and conditions
                of this Privacy Policy. If you do not agree please do not use or
                access the Website.
              </p>
              <p>
                By mere use of the Website, you expressly consent to our use and
                disclosure of your personal and non-personal information in
                accordance with this Privacy Policy.
              </p>
              <p>
                The Company has taken all reasonable measures to protect the
                confidentiality of the personal information and its transmission
                through the internet and it shall not be held liable for
                disclosure of the confidential information when in accordance
                with this Privacy Policy or in terms of the agreements, if any,
                with the user / subscribers. To preserve the confidentiality of
                all information you provide to us, the Company maintains the
                privacy principles mentioned hereinafter:
              </p>
              <p>
                The Company has policies and procedures concerning the
                collection, storage use and security of your information that
                will help protect your privacy. This Policy endeavours to help
                us serve you while maintaining strict privacy and security
                standards.
              </p>
              <ol>
                <li>
                  The Company collects several types of information, which is
                  primarily used to serve you better and maintain authenticity
                  of services and offerings by identifying you. Details of such
                  information (collectively called “Information”) are as
                  follows:
                  <ul>
                    <li>
                      Personal Information:
                      <p>
                        Personal Information is the information collected that
                        can be used to uniquely identify or contact you.
                        Personal Information for the purposes of this Privacy
                        Policy shall include, but not be limited to:
                      </p>
                      <ul>
                        <li>Your name</li>
                        <li>Your address,</li>
                        <li>Your telephone number,</li>
                        <li>
                          Your e-mail address or other contact information,
                        </li>
                        <li>Your date of birth,</li>
                        <li>Your gender,</li>
                        <li>
                          Information regarding your transactions on the Website
                        </li>
                        <li>
                          Your financial information such as bank account
                          information or credit card or debit card or other
                          payment instrument details,
                        </li>
                        <li>Internet Protocol address,</li>
                        <li>
                          Any other items of "sensitive personal data or
                          information" as such term is defined under the
                          Information Technology (Reasonable Security Practices
                          And Procedures And Sensitive Personal Data Of
                          Information) Rules, 2011 enacted under the Information
                          Technology Act, 2000;
                        </li>
                        <li>
                          Identification code of your communication device which
                          you use to access the Website or otherwise deal with
                          the Company,
                        </li>
                        <li>
                          Any other Information that you provide during your
                          registration process, if any, on the Website
                        </li>
                      </ul>
                    </li>
                    <li>
                      Non-Personal Information:
                      <p>
                        The Company may also collect information other than
                        Personal Information from You through the Website when
                        You visit and / or use the Website. Such information may
                        be stored in server logs. This Non-Personal Information
                        would not assist Company to identify you personally.
                      </p>
                      <p>
                        We will automatically receive and collect certain
                        anonymous information in standard usage logs through our
                        Web server, including computer-identification
                        information obtained from "cookies," sent to your
                        browser from a
                      </p>
                      <ul>
                        <li>Web server cookie stored on your hard drive</li>
                        <li>
                          An IP address, assigned to the computer which you use
                        </li>
                        <li>
                          The domain server through which you access our service
                        </li>
                        <li>The type of computer you're using</li>
                        <li>The type of web browser you're using</li>
                        <p>
                          You can terminate your account at any time. However,
                          your information may remain stored in archive on our
                          servers even after the deletion or the termination of
                          your account.
                        </p>
                      </ul>
                    </li>

                    <li>
                      Surveys:
                      <p>
                        The Company may email you or post various surveys on the
                        Website. The surveys may request for your demographic
                        information (age, gender, and familiarity with the
                        Website, your interests, content, products, services and
                        offerings) and your feedback on services and offerings.
                      </p>
                    </li>

                    <li>
                      Customer Service Communications Information:

                      <p>
                        The Company may collect Personal Information,
                        Non-Personal or Other Information when you post or
                        submit comments, questions, complaints or suggestions
                        regarding products, services and offerings.
                      </p>
                    </li>

                    <li>
                      Other Information:

                      <p>
                        The Company also collects information regarding your
                        usage of the Website and services (“Other Information”).
                        Other Information includes, but is not limited to
                        pattern of use of the Website by you, pages visited,
                        posts/comments made on any page of the Website etc.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  Mode of Collection of Information:
                  <ul>
                    <li>
                      When you use the Website, we collect and store your
                      Information which is provided by you from time to time.
                      Our primary goal in doing so is to provide you a safe,
                      efficient, smooth and customized experience. This allows
                      us to provide services and features that most likely meet
                      your needs, and to customize the Website to make your
                      experience safer and easier. More importantly, while doing
                      so we collect personal information from you that we
                      consider necessary for achieving this purpose.
                    </li>
                    <li>
                      In general, you can browse the Website without telling us
                      who you are or revealing any Personal Information about
                      yourself. Once you give us your Personal Information, you
                      are not anonymous to us. We may collect your Non-Personal
                      Information, just by you browsing your site. When
                      collecting personal information from you we indicate which
                      fields are required and which fields are optional. You
                      always have the option to not provide information by
                      choosing not to use a particular service or feature on the
                      Website. We may automatically track certain information
                      about you based upon your behaviour on the Website. We use
                      this information to do internal research on our users'
                      demographics, interests, and behaviour to better
                      understand, protect and serve our users. This information
                      is compiled and analysed on an aggregated basis. This
                      information may include the URL that you just came from
                      (whether this URL is on our website / app or not), which
                      URL you next go to (whether this URL is on the Website or
                      not), your computer browser information, and your IP
                      address.
                    </li>
                    <li>
                      We may receive Personal information about you from third
                      parties, such as social media services, commercially
                      available sources and business partners. If you access the
                      Website through a social media service or connect a
                      service on the Website to a social media service, the
                      information we collect may include your user name
                      associated with that social media service, any information
                      or content the social media service has the right to share
                      with us, such as your profile picture, email address or
                      friends list, and any information you have made public in
                      connection with that social media service. When you access
                      the Website or otherwise deal with the Company entity
                      through social media services or when you connect any the
                      Website to social media services, you are authorizing the
                      Company to collect, store, and use and retain such
                      information and content in accordance with this Privacy
                      Policy
                    </li>
                    <li>
                      We use data collection devices such as "cookies" on
                      certain pages of the the Website to help analyse our web
                      page flow, measure promotional effectiveness, and promote
                      trust and safety. We offer certain features that are only
                      available through the use of a "cookie". We also use
                      cookies to allow you to enter your password less
                      frequently during a session. Cookies can also help us
                      provide information that is targeted to your interests.
                      Most cookies are "session cookies" meaning that they are
                      automatically deleted from your hard drive at the end of a
                      session. You are always free to decline our cookies if
                      your browser permits, although in that case you may not be
                      able to use certain features on the Website and you may be
                      required to re-enter your password more frequently during
                      a session. Additionally, you may encounter "cookies" or
                      other similar devices on certain pages of the Website that
                      are placed by third parties. We do not control the use of
                      cookies by third parties.
                    </li>
                    <li>
                      If you choose to buy on the Website, we collect
                      information about your buying behaviour. If you transact
                      with us, we collect some additional information, such as a
                      billing address, a credit / debit card number and a credit
                      / debit card expiration date and/ or other payment
                      instrument details and tracking information from cheques
                      or money orders.
                    </li>
                    <li>
                      If you choose to post messages on our message boards, chat
                      rooms or other message areas or leave feedback, we will
                      collect that information you provide to us. We retain this
                      information as necessary to resolve disputes, provide
                      customer support and troubleshoot problems as permitted by
                      law.
                    </li>
                    <li>
                      If you send us personal correspondence, such as emails or
                      letters, or if other users or third parties send us
                      correspondence about your activities or postings on the
                      Website, we may collect such information into a file
                      specific to you.
                    </li>
                    <li>
                      We do use your contact information to send you offers
                      based on your previous orders and your interests.
                    </li>
                  </ul>
                </li>
                <li>
                  Use of Information:
                  <p>
                    The Company may also collect information other than Personal
                  </p>
                  <p>
                    We will automatically receive and collect certain anonymous
                  </p>
                  <ul>
                    <li>
                      The Company undertakes not to disclose (includes selling,
                      sharing and renting) Personal Information collected by the
                      Company at any time to any third party without your
                      consent except in accordance with this Privacy Policy.
                      However, the Company reserves the right to disclose
                      Information in good faith:
                      <ul>
                        <li>if required by law or any authority;</li>
                        <li>
                          to protect and defend rights and interests of the
                          Company, employees/ agents of the Company , other
                          users or the general public;
                        </li>
                        <li>
                          to enforce Terms of Use and other policies of the
                          Company;
                        </li>
                        <li>to address concerns raised by a third party.</li>
                      </ul>
                    </li>
                    <li>
                      The Company uses personal information to provide the
                      products, services and offerings. To the extent the
                      Company uses your personal information to market to you,
                      we will provide you the ability to opt-out of such uses.
                      We use your personal information to resolve disputes;
                      troubleshoot problems; help promote a safe service;
                      collect money; measure consumer interest in our products
                      and services, inform you about online and offline offers,
                      products, services, and updates; customize your
                      experience; detect and protect us against error, fraud and
                      other criminal activity; enforce our terms and conditions;
                      and as otherwise described to you at the time of
                      collection.
                    </li>
                    <li>
                      In our efforts to continually improve our product and
                      service offerings, we collect and analyse demographic and
                      profile data about our users' activity on the Website.
                    </li>
                    <li>
                      We identify and use your IP address to help diagnose
                      problems with our server, and to administer the Website.
                      Your IP address is also used to help identify you and to
                      gather broad demographic information.
                    </li>
                    <li>
                      We will occasionally ask you to complete optional online
                      surveys. These surveys may ask you for contact information
                      and demographic information (like zip code, age, or income
                      level). We use this data to tailor your experience at the
                      Website, providing you with content that we think you
                      might be interested in and to display content according to
                      your preferences.
                    </li>
                    <li>
                      Information collected can be used by the Company within
                      the company or by its affiliates/ contractors/ agents for
                      analysis and determination of statistics, demand and
                      supply, pattern of usage etc.
                    </li>
                    <li>
                      We may share personal information with our other corporate
                      entities and affiliates to help detect and prevent
                      identity theft, fraud and other potentially illegal acts;
                      correlate related or multiple accounts to prevent abuse of
                      our services; and to facilitate joint or co-branded
                      services that you request where such services are provided
                      by more than one corporate entity. Those entities and
                      affiliates may not market to you as a result of such
                      sharing unless you explicitly opt-in.
                    </li>
                  </ul>
                </li>
                <li>
                  Links to other websites:
                  <p>
                    The Website may carry links to other websites. Your use of
                    and activity at such other websites are at your own risk and
                    the Company takes no responsibility regarding Information
                    dissipated by you at such websites
                  </p>
                </li>
                <li>
                  <b> Data Security: </b>
                  <ul>
                    <li>
                      The Website has stringent security measures in place to
                      protect the loss, misuse, and alteration of the
                      information under our control. Whenever you change or
                      access your account information, we offer the use of a
                      secure server. Once your information is in our possession
                      we adhere to strict security guidelines, protecting it
                      against unauthorized access.
                    </li>

                    <li>
                      Your Information will primarily be stored in electronic
                      form however certain data can also be stored in physical
                      form. We may store, collect, process and use your data in
                      countries other than Republic of India but under
                      compliance with applicable laws. We may enter into
                      agreements with third parties (in or outside of India) to
                      store or process your information or data. These third
                      parties may have their own security standards to safeguard
                      your information or data and we will on commercial
                      reasonable basis require from such third parties to adopt
                      reasonable security standards to safeguard your
                      information / data.
                    </li>
                  </ul>
                </li>
                <li>
                  <b> Obligations of visitors/customers/users: </b>
                  <p>
                    To help the Company protect Information, the
                    visitors/customers/users are required to maintain the privy
                    of Information, ensure strength of password by using a
                    combination of alphabets, numbers and special character and
                    keep changing the password on regular basis. The
                    visitors/customers/users are also prohibited from
                    communicating information provided by the Company to a third
                    party unless required by law or in compliance with
                    agreements entered between visitors/customers/users and the
                    Company.
                  </p>
                </li>
                <li>
                  <b> Disclaimer as to Loss: </b>
                  <p>
                    Although, the Company has ensured the security of Personal
                    Information collected. However, considering the open nature
                    of Internet, such Information may be accessed by
                    unauthorised entities/ software/ codes (“Hackers”). The
                    Company assumes no liability whatsoever with respect to
                    access of Information by Hackers.
                  </p>
                </li>
                <li>
                  <b> Sale, merger of the Company: </b>
                  <p>
                    Information collected by the Company is considered to be
                    trade secret of the Company. As the owner of such trade
                    secret, the Company has right to disclose or sell the
                    Information as an asset of the Company during any sale or
                    merger.
                  </p>
                </li>
                <li>
                  <b> Your Consent: </b>
                  <p>
                    By using the Website and/ or by providing your information,
                    you consent to the collection and use of the information you
                    disclose on the Website in accordance with this Privacy
                    Policy, including but not limited to your consent for
                    sharing your information as per this Privacy Policy.
                  </p>
                </li>
                <li>
                  <b> Changes to this Policy: </b>
                  <p>
                    The Company reserves the right to amend this Policy, from
                    time to time, as required or in pursuance to change in
                    relevant laws.
                  </p>

                  <p>
                    If we decide to change our privacy policy, we will post
                    those changes on this page so that you are always aware of
                    what information we collect, how we use it, and under what
                    circumstances we disclose it. However, any substantial
                    change to the Policy would be communicated to you through
                    the Website or through email as provided by you on the
                    Website.
                  </p>
                </li>
                <li>
                  <b> Grievance: </b>
                  <p>
                    In case of any grievance, in respect of the Privacy Policy,
                    the Company may be contacted from Mon to Sat (9:00 to 17:00)
                    at the following coordinates:
                  </p>

                  <p>Email: grievances@blubirch.com</p>

                  <p>Phone: 080-26711673 /4 /6</p>

                  <p>
                    Address: {{Address}}
                  </p>
                </li>
              </ol>
            </v-col>          
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </section>
</template>

<script setup>
  import configuration from "@/config.json";
  const { Address } = configuration;
</script>