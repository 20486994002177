<template>
  <section class="about-banner">
    <v-img
      :src="abtBanner"
      class="d-lg-block d-md-none d-none"
      contain
      eager
      width="100%"
    ></v-img>
    <v-img
      :src="abtBanner2"
      class="d-lg-none d-md-block"
      contain
      eager
      width="100%"
    ></v-img>
  </section>

  <section class="about__uncover-section text-center section-padding">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2 class="heading">
            UNCOVER HIDDEN PROFITS FOR INVENTORY <br /> OWNERS AND DELIVER VALUE TO BUYERS
          </h2>
          <p class="para">
            What started as an innovative idea from a small room in Bangalore has translated into a fast growing company of 100 energetic and talented individuals that are disrupting the reverse inventory market using technology, machine learning and e-commerce to create economic, environmental and social value for retailers, manufacturers and enterprises.
            Our team is constantly innovating to tackle newer problems in reverse inventory management or to find more efficient ways to put assets back in the hands of users instead of landfills.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </section>

  <section class="about__recovery-section text-center section-padding">
    <v-container>
      <h1 class="heading">MAXIMUM RECOVERY</h1>
      <h4 class="sub-heading">
        BEAM - BULK4TRADERS ENGINE FOR ASSET MONETIZATION
      </h4>
      <p class="para">THE FUTURE OF REVERSE INVENTORY COMMERCE</p>
      <p class="para">
        End to End Tracking | Pricing Guidance | Business Insight And Analytics
        | Access to 1000s of Buyers
      </p>
      <v-img
        :src="flowImg"
        class="mt-14"
        eager
      ></v-img>
    </v-container>
  </section>

  <section class="about__vision-section section-padding">
    <v-container>
      <h2 class="heading">VISION</h2>
      <p class="para">
        "Enabling a sustainable circular economy by extending the life of products"
      </p>
    </v-container>
  </section>

  <section class="about__team-section d-none">
    <v-container>
      <h2 class="text-center heading">MEET THE TEAM</h2>
      <v-row>
        <v-col lg="4" sm="12" cols="12">
          <div class="team-section_box">
            <v-img
              :src="jeby"
              height="398"
              class="team-section_img"
              eager
            ></v-img>
            <h4 class="team-section_details">
              Jeby cherian
              <span class="team-section_designation"
                >Chairman & chief strategy Officer</span
              >
            </h4>
          </div>
        </v-col>
        <v-col lg="4" sm="12" cols="12">
          <div class="team-section_box">
            <v-img
              :src="sapan"
              height="398"
              class="team-section_img"
              eager
            ></v-img>
            <h4 class="team-section_details">
              SAPAN KUMAR JAIN<span class="team-section_designation"
                >CHIEF EXECUTIVE OFFICER</span
              >
            </h4>
          </div>
        </v-col>
        <v-col lg="4" sm="12" cols="12">
          <div class="team-section_box">
            <v-img
              :src="amit"
              height="398"
              class="team-section_img"
              eager
            ></v-img>
            <h4 class="team-section_details">
              AMIT GOEL
              <span class="team-section_designation"
                >CHIEF BUSINESS OFFICER</span
              >
            </h4>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>

  <section class="about__certification-section section-padding">
    <v-container>
      <h2 class="heading text-center">CERTIFICATIONS AND RECOGNITIONS</h2>
      <div class="justify-center certification-row d-flex flex-wrap">
        <div class="certification-col">
          <v-img :src="insight"></v-img>
        </div>
        <div class="certification-col">
          <v-img :src="medal"></v-img>
        </div>
        <div class="certification-col">
          <v-img :src="iso"></v-img>
        </div>        
        <div class="certification-col">
            <v-img :src="certLogo4"></v-img>
        </div>
        <div class="certification-col">
            <v-img :src="certLogo5"></v-img>
        </div>
        <div class="certification-col">
          <v-img :src="popControl"></v-img>
        </div> 
        <div class="certification-col">
          <v-img :src="award"></v-img>
        </div>
      </div>
    </v-container>
  </section>

  <section class="bulk4-section section-padding">
    <v-container>
      <div>
        <h2 class="heading text-center">BULK4TRADERS HQ</h2>
      </div>
      <v-carousel class="h-auto" :show-arrows="false" cycle>
        <v-carousel-item
          :src="teamImg"
          cover
        ></v-carousel-item>
      </v-carousel>
    </v-container>
  </section>
</template>

<script>
import app from "./index.js";

export default app;
</script>
