<template>
  <section class="buyer">
    <v-container>
      <v-col lg="8">
        <div class="hero-caption">
          <h1 class="sub-heading">
            A Trusted B2B Market Place To Buy Tested and Graded Excess,
            Returned, Refurnished, and Decommissioned inventory
          </h1>
          <div class="buyer-para mt-5">
            <p>Direct Access to Inventory from Trusted Brands and Retailers</p>
          </div>
          <v-row class="buyer-offer_list mt-4">
            <v-col lg="4" cols="12" md="4" sm="4" class="py-0">
              <div
                class="d-flex align-center justify-start mb-2 font-weight-bold"
              >
                <v-img
                  :src="group"
                  width="22"
                  height="22"
                  class="mr-2"
                  eager
                ></v-img>
                No Middleman
              </div>
            </v-col>
            <v-col lg="4" cols="12" md="4" sm="4" class="py-0">
              <div
                class="d-flex align-center justify-start mb-2 font-weight-bold"
              >
                <v-img
                  :src="group"
                  width="22"
                  height="22"
                  class="mr-2"
                  eager
                ></v-img>
                All Lot Sizes
              </div>
            </v-col>
            <v-col lg="4" cols="12" md="4" sm="4" class="py-0">
              <div
                class="d-flex align-center justify-start mb-2 font-weight-bold"
              >
                <v-img
                  :src="group"
                  width="22"
                  height="22"
                  class="mr-2"
                  eager
                ></v-img>
                Attractive Prices
              </div>
            </v-col>
          </v-row>
          <v-row class="align-center register-btn">
            <v-col lg="5" md="7" sm="7" cols="12">
              <v-btn
                size="large"
                color="blue"
                href="/signUp"
                variant="outlined"
                class="bg-white"
              >
                REGISTER AS A BUYER
              </v-btn>
            </v-col>
            <v-col lg="7" md="7" sm="7" cols="12" class="d-none d-md-block d-lg-block">
              <div class="counter-section">
                <div class="counter-box">
                  <v-row>
                    <v-col lg="3" class="text-center">
                      <p class="counters">70+</p>
                      <p class="counters-title">Sellers</p>
                    </v-col>
                    <v-col lg="4" class="text-center">
                      <p class="counters">32+</p>
                      <p class="counters-title">Cities</p>
                    </v-col>
                    <v-col lg="5" class="text-center">
                      <p class="counters">10,00,000+</p>
                      <p class="counters-title">Units Sold</p>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>

            <v-col lg="7" md="7" sm="7" cols="12" class="buyer-count_mobile d-lg-none d-md-none d-block">
              <div class="counter-section">
                <div class="counter-box">
                  <v-col lg="12" class="text-center">
                    <p class="counters">70+</p>
                    <p class="counters-title">Sellers</p>
                  </v-col>
                  <v-col lg="12" class="text-center">
                    <p class="counters">32+</p>
                    <p class="counters-title">Cities</p>
                  </v-col>
                  <v-col lg="12" class="text-center">
                    <p class="counters">10,00,000+</p>
                    <p class="counters-title">Units Sold</p>
                  </v-col>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-container>
  </section>

  <section class="why-b4">
    <v-container>
      <div class="whyb4-container">
        <h3 class="mb-4">Why B4Traders</h3>
        <p>
          B4Traders gives you access to
          <strong>high quality inventory</strong> with
          <strong>detailed manifest</strong> and
          <strong>published directly by brands and retailers.</strong> You
          decide how much you are willing to pay for the inventory on offer!
        </p>
      </div>
    </v-container>
  </section>
  <section class="steps">
    <v-container>
      <div class="step">
        <v-row>
          <v-col lg="6" md="6" cols="12" align-self="center">
            <h3>Step 1: Register Here</h3>
            <p>
              Choose the Brand or Retailer store you want to buy from. Read the
              terms of purchase and with few simple steps register for the
              store’s access.
            </p>
            <v-btn color="blue" size="large" href="/signUp">REGISTER AS A BUYER </v-btn>
          </v-col>
          <v-col lg="6" md="6" cols="12">
            <v-img
              :src="step1"
              class="w-75"
              eager
            ></v-img>
          </v-col>
        </v-row>
      </div>
      <div class="step step-gap">
        <v-row>
          <v-col md="6" cols="12" lg="6" class="order-2 order-lg-1">
            <v-img
              :src="step2"
              class="w-75"
              eager
            ></v-img>
          </v-col>
          <v-col md="6" cols="12" lg="6" align-self="center" class="order-1 order-lg-2">
            <h3>Step 2: Get Approved</h3>
            <p>
              We will inform you as soon as your registration is approved. Our
              team will help you with the approval process
            </p>
          </v-col>
        </v-row>
      </div>
      <div class="step step-gap">
        <v-row>
          <v-col md="6" cols="12" lg="6" align-self="center" class="mb-2">
            <h3>Step 3: Browse</h3>
            <p>
              View available inventory from top retailers and brands across
              conditions and at attractive prices from a variety of categories.
              You can use our filter for focused browsing
            </p>
          </v-col>
          <v-col md="6" cols="12" lg="6" class="mb-2">
            <v-img
              :src="step3"
              class="w-75"
              eager
            ></v-img>
          </v-col>
        </v-row>
      </div>
      <div class="step step-gap">
        <v-row>
          <v-col md="6" cols="12" lg="6" class="order-2 order-lg-1">
            <v-img
              :src="step4"
              class="w-75"
              eager
            ></v-img>
          </v-col>
          <v-col md="6" cols="12" lg="6" align-self="center" class="order-1 order-lg-2">
            <h3>Step 4: Bid your offer</h3>
            <p>
              Place your bid. We will notify you if you have been out-bidded.
            </p>
            <p>
              Oh Wait! Like the inventory so much you want to buy right away?
              Easy – click on buy now price and we will take the inventory off
              the auction.
            </p>
          </v-col>
        </v-row>
      </div>
      <div class="step step-gap">
        <v-row>
          <v-col md="6" cols="12" lg="6" align-self="center" class="mb-2">
            <h3>Step 5: Win</h3>
            <p>
              We will notify you if you have won. You can pay immediately and
              plan for pickup and Delivery.
            </p>
          </v-col>
          <v-col md="6" cols="12" lg="6" class="mb-2">
            <v-img
              :src="step5"
              class="w-75"
              eager
            ></v-img>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </section>
  <KeyPartners />
  <Featured />
  <RegisterBanner />
</template>

<script>
  import app from './index.js';
  export default app;
</script>