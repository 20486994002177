import { createApp } from 'vue/dist/vue.esm-bundler';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import 'vuetify/dist/vuetify.min.js'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import * as labsComponents from "vuetify/labs/components"
import vSelect from "vue-select";
import 'vuetify/styles'
import 'vuetify/dist/vuetify.min.css'
import "vue-select/dist/vue-select.css";
import '@mdi/font/css/materialdesignicons.css'

import router from '../router';
import App from "./app.vue"
import VueAxios from 'vue-axios';
import { axiosInstance, scbAxiosInstance } from "../services"

const vuetify = createVuetify({
  ssr: true,
  components: {
    ...components,
    ...labsComponents
  },
  directives,
})

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App)
app.component("v-selector", vSelect);
app.use(VueAxios, { axiosInstance, scbAxiosInstance })
app.use(router)
app.use(pinia)
app.use(vuetify)

app.mount('#app');
