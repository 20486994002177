<template>
  <div class="footer">
    <v-card class="mx-auto pa-5 footer-background">
      <v-container style="max-width: 1320px">
        <v-row justify="center">
          <v-col cols="12" lg="4" sm="12">
            <v-img
              :src="logoImage"
              clcc
              height="100px"
              contain
              position="top left"
              class="logoImg"
              @click="handleRedirect"
            />
          </v-col>
          <v-col lg="2" sm="4">
            <ul class="footer-services">
              <li>
                <router-link to="/aboutus">About Us</router-link>
              </li>
              <li>
                <router-link to="/contactus">Contact Us</router-link>
              </li>
              <li>
                <router-link to="/faq">FAQ</router-link>
              </li>
            </ul>
          </v-col>
          <v-col lg="2" sm="4">
            <ul class="footer-services">
              <li>
                <router-link to="/tnc">Terms of Purchase</router-link>
              </li>
              <li>
                <router-link to="/item_conditions">Items Condition</router-link>
              </li>
              <li>
                <router-link to="/privacypolicy">Privacy Policy</router-link>
              </li>
            </ul>
          </v-col>
          <v-col cols="12" lg="2" sm="4">
            <ul class="footer-services">
              <li>
                <v-icon color="#092D66" size="x-small">mdi-phone</v-icon>
                <a href="tel:1800-419-0431"> 1800-419-0431</a>
              </li>
              <li>
                <v-icon color="#092D66" size="x-small">mdi-email</v-icon>
                <a href="mailto:support@b4traders.com">
                  support@b4traders.com</a
                >
              </li>
            </ul>
          </v-col>
          <v-col cols="12" lg="2" sm="12">
            <ul class="footer-services">
              <li>
                <v-icon
                  color="#092D66"
                  size="x-small"
                  icon="mdi-map-marker"
                  class="mr-2"
                ></v-icon>
                <a
                  href="https://g.page/blubirch?share"
                  >{{Address}}</a
                >
              </li>
            </ul>
          </v-col>
        </v-row>
        <v-row justify="center" class="text-center">
          <v-col cols="12" lg="5" md="12">
            <b
              >© B4traders - Trade mark of Green Enabled IT Solutions Pvt.
              Ltd</b
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script setup>
  import logoImage from "@/../assets/images/logo.png";
  import { useRouter } from "vue-router";
  import configuration from "@/config.json";

  const router = useRouter();
  const handleRedirect = () => {
    router.push({ name: "Dashboard" });
  }
  const { Address } = configuration;
</script>
