<template>
  <v-card>
    <v-row class="register-success-banner">
      <v-col sm="12" md="3" cols="12" class="order-md-1 order-2">
        <v-img
          :src="checkImage"
          :lazy-src="checkImage"
          height="175px"
          contain
          class="card-img"
          style="cursor: pointer"
        ></v-img>
      </v-col>
      <v-col sm="12" md="9" cols="12" class="order-md-2 order-1">
        <h1>Form Submitted Successfully</h1>
        <p>
          While your form is being reviewed and approved, Please view our
          auctions
        </p>
        <p>
          you will receive approval notification on your registered email ID.
        </p>
        <p class="marketplace-title">Registered Marketplaces</p>
        <v-row class="cards" v-if="!loading">
          <v-col
            v-for="(service, index) in services"
            :key="index"
            cols="12"
            md="3"
            lg="3"
            xl="3"
            sm="6"
            xs="12"
            class="marketplace-card"
          >
            <div class="icon-box-content">
                <v-img
                :src="service.image"
                :lazy-src="service.image"
                height="175px"
                contain
                class="card-img"
                @click="handleRedirect(service.link, service?.bidType)"
                style="cursor: pointer"
                ></v-img>
            </div>
          </v-col>
        </v-row>
        <v-col lg="12" sm="12" md="12" v-else>
          <v-skeleton-loader
            class="mx-auto border"
            type="image, article"
          ></v-skeleton-loader>
        </v-col>
        <v-btn class="mt-10 me-4 bg-blue" size="large" @click="handleRedirect('/products')"> View Auctions </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import app from "./success.js";
export default app;
</script>
