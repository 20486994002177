<template>
  <v-responsive>
    <div class="header">
      <v-layout>
        <v-app-bar>
          <v-app-bar-nav-icon
            variant="text"
            @click.stop="headerState.drawer = !headerState.drawer"
            color="#0357D0"
            class="d-lg-none d-md-block d-sm-block d-block  pl-0 ml-0"
          ></v-app-bar-nav-icon>
          <v-img
            :src="logoImage"
            height="150px"
            class="logoImg sm-logo-size cursor-pointer"
            @click="() => handleRedirect('Dashboard')"
          />
          <v-btn
            class="hidden-md-and-down"
            @click="() => redirectToProducts()"
            >Shop All Auctions</v-btn
          >
          <v-btn
            class="hidden-md-and-down"
            @click="() => handleRedirect('Buyers')"
            >For Buyers</v-btn
          >
          <v-btn
            class="hidden-md-and-down"
            @click="() => handleRedirect('Sellers')"
            >For Sellers</v-btn
          >
          <v-btn @click="() => handleRedirect('Support')" class="hidden-md-and-down">Support </v-btn>
          <v-btn
            class="hidden-md-and-down"
            variant="elevated"
            color="blue"
            @click="() => handleRedirect('Home')"
            >BROWSE MARKETPLACES</v-btn
          >

          <LoginPage />
          <ForgotPasswordPage />
          <CreatePassword />
          <LoginOTPPage />
          <template v-if="profileVisible">
            <ProfileDetails />
          </template>
          <ChangePassword />
          <OTPPage />
          <GoogleTranslator v-if="!isMobile()" />
        </v-app-bar>
        <v-navigation-drawer
          v-model="headerState.drawer"
          location="left"
          class="h-100 drawer-top"
          temporary
        >
          <v-list-item
            class="offcanvas-box v-icon--size-extralarge"
            prepend-icon="profile mdi-account-circle"
            prepend-avatar=""
            :title="user.first_name ? `Welcome, ${user?.first_name}` : 'Welcome'"
          ></v-list-item>
          <v-divider></v-divider>
          <v-list density="compact" nav v-if="user?.authentication_token">
            <v-list-item
              title="My Bids"
              value="My Bids"
              @click="() => handleRedirect('MyBids')"
            ></v-list-item>
            <v-list-item
              title="My Orders"
              value="My Orders"
              @click="() => handleRedirect('MyOrders')"
            ></v-list-item>
            <v-list-item
              title="My Profile"
              value="My Profile"
              @click="() => showProfile()"
            ></v-list-item>
            <v-list-item
              title="Change Password"
              value="Change Password"
              @click="handleChangePassword"
            ></v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list density="compact" nav>
            <v-list-item
              title="Shop All Auctions"
              value="Shop All Auctions"
              @click="() => redirectToProducts()"
            ></v-list-item>
            <v-list-item @click="() => handleRedirect('Buyers')" title="For Buyers" value="For Buyers"></v-list-item>
            <v-list-item @click="() => handleRedirect('Sellers')" title="For Sellers" value="For Sellers"></v-list-item>
            <v-list-item @click="() => handleRedirect('Support')" title="Support" value="about"></v-list-item>
            <v-list-item @click="() => handleRedirect('Home')" title="Browse MarketPlaces" value="Browse MarketPlaces"></v-list-item>
          </v-list>
          <v-div class="footer-actions">
            <GoogleTranslator v-if="isMobile()" />

            <v-btn
              variant="outlined"
              color="#092D66"
              elevation="0"
              v-if="!user?.authentication_token"
              @click="userSignin"
            >
              SignIn
            </v-btn>

            <v-btn
              variant="outlined"
              color="#092D66"
              elevation="0"
              v-if="user?.authentication_token"
              @click="userSignin"
            >
              Sign Out
            </v-btn>
          </v-div>
        </v-navigation-drawer>
      </v-layout>
    </div>
  </v-responsive>
  <span v-if="configuration.demo" class="cr cr-top cr-left cr-sticky cr-red">{{configuration.ribbonText}}</span>
  <a href="https://api.whatsapp.com/send?phone=+919481359961&amp;text=Hello%21%20." target="_blank" class="whatsapp-icon">
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" fill="#fff" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg>
  </a>
</template>

<script src="./header.js" />