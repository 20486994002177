<template>
  <v-card class="contact-us">
    <v-card-title class="d-flex justify-center align-center text-center mb-3">
      Seller Contact Form
    </v-card-title>

    <form @submit.prevent="submit" class="contact-form">
      <v-text-field
        variant="outlined"
        label="Name"
        class="required"
        v-model="name.value.value"
        :error-messages="name.errorMessage.value"
      ></v-text-field>

      <v-text-field
        variant="outlined"
        label="Phone Number"
        type="number"
        counter="10"
        class="required"
        maxLength="10"
        v-model="phone.value.value"
        :error-messages="phone.errorMessage.value"
      ></v-text-field>

      <v-text-field
        variant="outlined"
        type="email"
        class="required"
        label="Email"
        v-model="email.value.value"
        :error-messages="email.errorMessage.value"
      ></v-text-field>

      <v-select
        label="Subject"
        :items="subjects"
        variant="outlined"
        class="required"
        required
        v-model="subject.value.value"
        :error-messages="subject.errorMessage.value"
      ></v-select>

      <v-textarea
        label="Remarks"
        class="required"
        variant="outlined"
        v-model="remarks.value.value"
        :error-messages="remarks.errorMessage.value"
      ></v-textarea>

      <v-card-actions class="justify-center">
        <v-btn
          variant="elevated"
          class="bg-theme-primary"
          type="submit"
          size="large"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </form>
  </v-card>
</template>

<script>
  import app from './index.js';

  export default app;
</script>
