const LoginPage = defineAsyncComponent({
  loader: () =>import("../login/LoginPage.vue"),
})

const ChangePassword = defineAsyncComponent({
  loader: () =>import("../ForgotPassword/ChangePassword.vue")
})

const ProfileDetails = defineAsyncComponent({
  loader: () =>import("../Profile/Details.vue")
})

const CreatePassword = defineAsyncComponent({
  loader: () =>import("../CreatePassword/index.vue")
})

const ForgotPasswordPage = defineAsyncComponent({
  loader: () =>import("../ForgotPassword/index.vue")
})

const LoginOTPPage = defineAsyncComponent({
  loader: () =>import("../login/OtpLogin.vue")
})

const OTPPage = defineAsyncComponent({
  loader: () =>import("../ForgotPassword/ConfirmOTP.vue")
})

const GoogleTranslator = defineAsyncComponent({
  loader: () =>import("./GoogleTranslator.vue"),
})

import logoImage from "@/../assets/images/logo.png";
import { useAuth, usePaginationStore } from "../../stores";
import { isMobile } from "../../misc/index";
import { computed, reactive } from "vue";
import { storeToRefs } from 'pinia';
import configuration from "../../config.json";
import { defineAsyncComponent } from 'vue';

export default {
  setup() {
    const authStore = useAuth();
    const user = computed({
      get: () => authStore.user,
    });

    const profileVisible = computed({
      get: () => authStore.profileDialog,
    });

    const headerState = reactive({
      drawer: false,
    });

    const handleChangePassword = () => {
      authStore.setChangePasswordDialog(true);
    };

    const showProfile = () => {
      headerState.drawer = !headerState.drawer
      authStore.setProfileDialog(true);
    };

    const userSignin = () => {
      headerState.drawer = false;
      if (user?.value?.authentication_token) {
        authStore.signOut("");
      } else {
        authStore.setLoginDialog(true);
      }
    };
    return { user, showProfile, configuration, headerState, userSignin, handleChangePassword, profileVisible };
  },

  components: {
    LoginPage,
    GoogleTranslator,
    ForgotPasswordPage,
    ChangePassword,
    CreatePassword,
    LoginOTPPage,
    OTPPage,
    ProfileDetails,
  },

  data: () => ({
    logoImage: logoImage,
  }),

  methods: {
    redirectToProducts() {
      const pageStore = usePaginationStore();
      pageStore.setPage(1);
      this.$router.push({ name: 'Products' });
      // window.location.href = '/products'
      this._.setupState.headerState.drawer = !this._.setupState.headerState.drawer
    },
    handleRedirect(componetName) {
      this.$router.push({ name: componetName });
      this._.setupState.headerState.drawer = !this._.setupState.headerState.drawer
    },
    isMobile: isMobile,
  },
};