<template>
  <section class="terms-banner">
    <v-img
      src="https://blubirch.com/images/inner-banner-9.png"
      class="d-lg-block"
      contain
      width="100%"
    ></v-img>
    <div class="inner_hero1">
      <v-container>
        <h3 class="inner_head1">TERMS OF USE</h3>
      </v-container>
    </div>
  </section>

  <section class="terms-section section-padding">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2 class="heading mt-5 text-center">TERMS OF USE</h2>
          <ol class="terms-list">
            <li>
              General
              <ol>
                <li>
                  This document is an electronic record in terms of Information
                  Technology Act, 2000 and rules made there under as applicable
                  and the amended provisions pertaining to electronic records in
                  various statutes as amended by the Information Technology Act,
                  2000. This electronic record is generated by a computer system
                  and does not require any physical or digital signatures.
                </li>
                <li>
                  This document is published in accordance with the provisions
                  of Rule 3 (1) of the Information Technology (Intermediaries
                  Guidelines) Rules, 2011 that require publishing the rules and
                  regulations and user agreement for access or usage of
                  [www.blubirch.com] website and associated mobile application
                </li>
                <li>
                  Your access and use of www.blubirch.com and Buyer App mobile
                  application (together hereinafter referred to as the
                  "Website") and its related sites, services and tools is
                  governed by the terms and conditions as contained in this
                  agreement applicable to the Website(hereinafter referred to as
                  the "User Agreement"). This User Agreement shall come into
                  effect upon access to the Website by the User.
                </li>
                <li>
                  The Website comprises of an internet based portal and mobile
                  application owned and operated by M/s Green Enabled IT
                  Solutions Private Limited, a company incorporated under
                  Companies Act, 2013 having its registered office at
                  Bansashankari, Bangalore - 560 085, Karnataka, India
                  (hereinafter referred to as “Blubirch”).
                </li>
                <li>
                  Use of the Website is strictly meant only for business to
                  business (b2b) transactions
                </li>
                <li>
                  The use of the Website and services provided herein through is
                  offered to you conditioned on your ‘acceptance’ of all the
                  terms, conditions and notices contained in this User
                  Agreement. Upon "acceptance", this User Agreement shall be
                  effective and binding upon you along with any amendments made
                  by Blubirch at its sole discretion and posted on the Website
                  and you shall not claim invalidity of this User Agreement
                  merely on the grounds that this User Agreement is being
                  concluded electronically.
                </li>
                <li>
                  For the aforesaid purposes, 'acceptance' shall mean your
                  affirmative action in clicking on 'check box' and on the
                  'continue button' as provided on the registration page or
                  clicking on 'Buy Now' button while transacting as Guest User
                  or any act which reflects your use of the Website or the
                  services provided by the Website or any such other actions
                  that implies your acceptance.
                </li>
                <li>
                  Your use of the Website implies that you agree with the terms
                  of the User Agreement in entirety. If you do not agree or are
                  not willing to be bound by the terms and conditions of this
                  User Agreement and other terms and policies as displayed on
                  the Website, please do not click on the "check box" and/or on
                  the "continue" button and/or "Buy Now" button and do not seek
                  to obtain access to or otherwise use the Website.
                </li>
                <li>
                  For the purpose of this User Agreement, Registered User /
                  Guest User and wherever the context so require '“you”, “your”
                  shall mean any natural or legal person who is accessing the
                  Website, its contents and using the services offered on or
                  through the Website and has agreed to become a user / member
                  of the Website by providing Registration Data (as defined
                  hereinafter) while registering on the Website as Registered
                  User / Guest User using the computer systems of the Website
                  and accepted this electronic version / electronic record of
                  the User Agreement and has allocated himself/herself a unique
                  identification user name ("User ID" and "Password") to become
                  Registered User or have been identified as Guest User by
                  providing phone number and email id. Further, reference to
                  “we”, “us”, “our” and “Blubirch” shall mean M/s Green Enabled
                  IT Solutions Private Limited and includes its associates,
                  affiliates and subsidiaries.
                </li>
                <li>
                  When you use any of the services provided by us through the
                  Website, you will be subject to the rules, guidelines,
                  policies, terms, and conditions applicable to such service,
                  and they shall be deemed to be incorporated into this User
                  Agreement and shall be considered as part and parcel of this
                  User Agreement. We reserve the right, at our sole discretion,
                  to change, modify, add or remove portions of the User
                  Agreement, at any time without any prior written notice to
                  you. It is your responsibility to review this User Agreement
                  periodically for updates / changes. Your continued use of the
                  Website following the posting of changes will mean that you
                  accept and agree to the revisions. As long as you comply with
                  the provisions of this Agreement, we grant you a
                  non-exclusive, non-transferable, limited privilege to enter
                  and use the Website.
                </li>
                <li>
                  By accepting this User Agreement, you also accept and agree to
                  be bound by various rules and policies as available and
                  provided on the Website from time to time.
                </li>
              </ol>
            </li>
            <li>
              Transaction Eligibility
              <ol>
                <li>
                  Use of the Website is available only to persons who can form
                  legally binding contracts under Indian Contract Act, 1872.
                  Persons who are "incompetent to contract" within the meaning
                  of the Indian Contract Act, 1872 including minors,
                  un-discharged insolvents etc. are not eligible to use the
                  Website. If you are a minor i.e. under the age of 18 years,
                  you shall not register as a member of the Website and shall
                  not purchase or bid for any items on the Website. You
                  represent that you are duly authorized to accept this User
                  Agreement and you have the authority to bind your business
                  entity to this User Agreement. Blubirchreserves the right to
                  terminate your membership and refuse to provide you with
                  access to the Website if it is brought to our notice or if it
                  is discovered that you are under the age of 18 years.
                </li>
                <li>
                  If you are transacting as a Guest User, there could be certain
                  restrictions on you as a Guest User for your purchase and the
                  other functionality of the Website available to you. As a
                  guest user, you will not be eligible for all other benefits
                  that are not assignable to a non-registered user.
                </li>
                <li>
                  Membership on the Website is free. Blubirch does not charge
                  any fee for browsing and buying on the Website.
                </li>
              </ol>
            </li>
            <li>
              Your Account and Registration Obligations
              <ol>
                <li>
                  If you use the Website as Registered User, you are responsible
                  for maintaining the confidentiality of your User ID and
                  Password. If you use the Website as a Guest User you are
                  responsible for maintaining the confidentiality of your email
                  ID and phone number and restricting access to your computer,
                  computer system and computer network. You are responsible for
                  all activities that occur under your User ID and Password (if
                  registered) or email id and phone number (if Guest User) as
                  the case may be. Also as a Guest User, you shall ensure the
                  physical security and possession of your computer system, the
                  details of which are used to transact services on the Website.
                </li>
                <li>
                  You agree, inter alia, to: (i) provide true, accurate, current
                  and complete information about yourself as prompted by
                  Website’s registration form or provided by you as Guest User
                  (such information shall hereinafter be referred to as the
                  "Registration Data"); and (ii) maintain and promptly update
                  the Registration Data to keep it true, accurate, current and
                  complete at all times; (iii) immediately notify us of any
                  unauthorized use of your User ID and Password or any other
                  breach of security, and (iv) ensure that you sign out / exit
                  from your account at the end of each session. If you provide
                  any information that is untrue, inaccurate, not current or
                  incomplete or if Blubirch has reasonable grounds to suspect
                  that such information is untrue, inaccurate, not current or
                  incomplete, or not in accordance with the User Agreement,
                  Blubirch shall have the right to indefinitely suspend or
                  terminate or block access of your use with the Website and
                  refuse to provide you with access to the Website.Blubirch
                  shall not be liable for any loss or damage arising from your
                  failure to comply provisions of this User Agreement.
                </li>
                <li>
                  By registering on the Website and / or by providing us with
                  the Registration Data, including your email id., and mobile
                  number to us, you agree and consent to receiving from us,
                  communications with respect to products, transactions and
                  other business related information,through physical and
                  electronic means including phone calls, emails and SMS
                  notifications.
                </li>
                <li>
                  When you use the Website or send emails or other data,
                  information or communication to Blubirch, you agree and
                  understand that you are communicating with Blubirch through
                  electronic records. You consent to receive communications from
                  us electronically. We will communicate with you by email or by
                  posting notices on the Website or through any other means. You
                  agree that all the agreements, notices, disclosures and other
                  communications that we provide to you electronically shall be
                  deemed adequate service of notice/electronic record and
                  satisfy any legal requirement that such communication be in
                  writing
                </li>
              </ol>
            </li>
            <li>
              Use of the Website
              <ol>
                <li>
                  “Your Information” is defined as any information you provide
                  to us or other users of the Website ("Users") in the
                  registration (as Registered User or as Guest User), buying, in
                  the feedback area or through any e-mail feature. You are
                  solely responsible for Your Information, and we act only as a
                  passive conduit for your online distribution and publication
                  of Your Information.
                </li>
                <li>
                  Please note that in accordance with the Information Technology
                  (Intermediaries Guidelines) Rules 2011, in case of
                  non-compliance with rules and regulations, user agreement and
                  privacy policy, for access or usage of intermediary computer
                  resource, the Intermediary has the right to immediately
                  terminate the access or usage rights of the users to the
                  computer resource of Intermediary and remove non-compliant
                  information.
                </li>
                <li>
                  In terms of Information Technology (Intermediaries Guidelines)
                  Rules, 2011, you agree and undertake that you shall not host,
                  display, upload, modify, publish, transmit, update or share
                  any information or share/list(s) any information or item that:
                  <ul>
                    <li>
                      belongs to another person and to which You do not have any
                      right;
                    </li>
                    <li>
                      is grossly harmful, harassing, blasphemous, defamatory,
                      obscene, pornographic, paedophilic, libellous, invasive of
                      another's privacy, hateful, or racially, ethnically
                      objectionable, disparaging, relating or encouraging money
                      laundering or gambling, or otherwise unlawful in any
                      manner whatever; or unlawfully threatening or unlawfully
                      harassing including but not limited to "indecent
                      representation of women" within the meaning of the
                      Indecent Representation of Women (Prohibition) Act, 1986;
                    </li>
                    <li>harm minors in any way;</li>
                    <li>
                      infringes any patent, trademark, copyright or other
                      proprietary rights or third party's trade secrets or
                      rights of publicity or privacy or shall not be fraudulent
                      or involve the sale of counterfeit or stolen items;
                    </li>
                    <li>
                      involves the transmission of "junk mail", "chain letters",
                      or unsolicited mass mailing or "spamming";
                    </li>
                    <li>violates any law for the time being in force;</li>
                    <li>
                      deceives or misleads the addressee/ users about the origin
                      of such messages or communicates any information which is
                      grossly offensive or menacing in nature;
                    </li>
                    <li>impersonate another person;</li>
                    <li>
                      contains software viruses or any other computer code,
                      files or programs designed to interrupt, destroy or limit
                      the functionality of any computer resource; or contains
                      any Trojan horses, worms, time bombs, cancel-bots,
                      easter-eggs or other computer programming routines that
                      may damage, detrimentally interfere with, diminish value
                      of, surreptitiously intercept or expropriate any system,
                      data or personal information;
                    </li>
                    <li>
                      threatens the unity, integrity, defence, security or
                      sovereignty of India, friendly relations with foreign
                      states, or public order or causes incitement to the
                      commission of any cognizable offence or prevents
                      investigation of any offence or is insulting any other
                      nation;
                    </li>
                    <li>is false, inaccurate or misleading;</li>
                    <li>
                      links directly or indirectly to or include descriptions of
                      items, goods or services that are prohibited under the
                      User Agreement or any other applicable law for the time
                      being in force.
                    </li>
                  </ul>
                </li>
                <li>
                  By accepting this User Agreement, you also accept and agree to
                  be bound by various rules and policies as available and
                  provided on the Website from time to time.
                </li>
                <li>
                  Blubirch shall have the right to use Your Information as
                  provided by you and such usage of your information by us does
                  not amount to violation of any rights you might have in Your
                  Information. You agree to grant us a non-exclusive, worldwide,
                  perpetual, irrevocable, royalty-free, sub-licensable (through
                  multiple tiers) right to exercise the copyright, publicity,
                  and database rights (but no other rights) you have in Your
                  Information, in any media now known or not currently known,
                  with respect to Your Information.
                </li>
                <li>
                  You shall at all times ensure full compliance with the
                  applicable provisions of the Information Technology Act, 2000
                  and rules made there under as applicable and as amended from
                  time to time and also all applicable laws, rules and
                  regulations.
                </li>
                <li>
                  You understand and agree that certain content on the Website
                  may be third party generated content. Blubirch neither
                  originates nor initiates such transmission nor selects the
                  sender and receiver of the transmission nor selects nor
                  modifies the information contained in the transmission.
                  Blubirch has no control over the third party user generated
                  contents and does not provide any guarantee with respect to
                  any such content and Blubirch shall not be held liable for any
                  loss suffered by you based on your reliance on or use of such
                  data/content.
                </li>
              </ol>
            </li>
            <li>
              Transactions on the Website
              <ol>
                <li>
                  The sale – purchase of the products shall be executed strictly
                  as per the terms and conditions on the Website and description
                  of the product and terms of sale mentioned on the Website.
                </li>
                <li>
                  We have made every effort to display the description of the
                  products proposed to be sold by us that appear on the Website
                  as accurately as possible. Blubirch holds no warranty
                  regarding the quality, merchantability, service of products.
                  Description of products sold by other sellers is
                  responsibility of such sellers.
                </li>
                <li>
                  Blubirch may not be seller of all the products displayed for
                  sale on the Website. The products sold on the Website may be
                  sold by Blubirch or by other seller registered with Blubirch
                  for the said purpose. Please identify the seller of the
                  product carefully before proceeding with the transaction. The
                  Products being sold by Blubirch are on an ‘as is’ basis.
                  Blubirch does not make any representation or warranty as to
                  the attributes (such as quality, worth, marketability,
                  merchantability, fitness for any purpose etc.) of the products
                  proposed to be sold or offered to be sold or purchased on the
                  Website.
                </li>
                <li>
                  The commercial / contractual terms including without
                  limitation price, shipping costs, payment methods, payment
                  terms, date, period and mode of delivery, warranties and after
                  sales services related to goods, if any, shall be strictly
                  adhered to by the buyer
                </li>
                <li>
                  Title to all the products shall remain with Blubirch / other
                  sellers unless the User buys the product and makes the payment
                  for the same. Notwithstanding anything, once the products have
                  been shipped from warehouse or handed over to buyer the risk
                  of goods transfers to buyer.
                </li>
                <li>
                  All purchases on the Website are a firm commitment to
                  purchase. If you are the purchaser for any item, you are
                  obligated to complete the transaction.
                </li>
                <li>
                  By purchasing an item you agree to be bound by the conditions
                  of sale included in the item's description (or linked to from
                  the description) so long as those conditions of sale are not
                  in violation of the User Agreement or unlawful
                </li>
                <li>
                  The retail prices of the products proposed to be sold by
                  Blubirch as mentioned on the Website are identified by
                  Blubirch on a best effort basis from various third sources and
                  Blubirch does not guarantee correctness of such retail price.
                  You are advised to independently verify the retail prices of
                  such items if you so desire.
                </li>
                <li>
                  You acknowledge and undertake that you are accessing the
                  products on the Website and transacting at your own risk and
                  are using your best and prudent judgment before entering into
                  any transactions through the Website. The products proposed to
                  be sold by Blubirch / other seller are being sold on an ‘as
                  is’ basis and Blubirch shall not be responsible for any
                  defects or damages to the products which have already been
                  disclosed on the Website prior to sale. Blubirch shall not be
                  liable or responsible for any actions, or inactions or any
                  breach of conditions, representations or warranties by other
                  sellers or the manufacturers of the products and hereby
                  expressly disclaim any and all responsibility and liability in
                  that regard.
                </li>
                <li>
                  Blubirch strongly advises you not to test the Website with
                  false purchases, as it will put you at substantial personal
                  legal risk. It is an offence to use a false name, other false
                  information or a known invalid/ unauthorized credit/ debit/
                  cash card and such other infrastructure directly through bank
                  or financial institution infrastructure or indirectly through
                  payment aggregator. Wilfully entering erroneous or fictitious
                  bids may result in legal action.
                </li>
                <li>
                  Please note that in the event any inaccurate information is
                  provided by you regarding your identity, your web browser
                  transmits a unique address to us, which can be used by law
                  enforcement officials to identify you.
                </li>
                <li>
                  In case of purchase of any product which is marked as
                  "non-exportable" on the Website or it is illegal to export the
                  same, you agree that the product shall not be exported out of
                  India or otherwise dealt in any such manner which shall be in
                  contravention of any law for the time being in force or any
                  rules, regulations, notifications and orders passed there
                  under.
                </li>
                <li>
                  Once a bid is placed for any lot, the buyer shall be obliged
                  to fulfil and complete the transaction if the lot is offered
                  to it, within 72 hours of bid closure (excluding bank
                  holidays). If the buyer fails to meet his obligation, Blubirch
                  has the right to block the buyer temporarily or permanently
                  from the Websiteat its sole discretion.
                </li>
                <li>
                  In case of mismatch in manifest and corresponding lot with
                  respect to products being sold by Blubirch exceeds 4% (by
                  value), then Blubirch at its sole discretion can either: (i)
                  adjust the lot price based on fair market value (as assessed
                  by Blubirch) of missing items or items with incorrect grade;
                  or (ii) cancel the complete bid.
                </li>
                <li>
                  Access to the Website may be disrupted due to disruption in
                  the networks. Blubirch will not be responsible in issues
                  arising out of disruption.
                </li>
              </ol>
            </li>
            <li>
              Payments
              <p>
                Blubirch uses third party payment providers to receive payments
                from buyers. Blubirch is not responsible for delays or erroneous
                transaction execution or cancellation of orders due to payment
                issues. Blubirch takes utmost care to work with 3rd party
                payment providers, but does not control their systems,
                processes, technology and work flows, hence cannot be held
                responsible for any fault at the end of payment providers.
              </p>
            </li>
            <li>
              Cancellation by Site / Customer
              <p>
                Once you place the order for purchase of a product, you shall
                not be entitled to purchase it. Once a bid is made by you, you
                shall be obliged to fulfil and complete the transaction if your
                bid is successful. If we suspect any fraudulent transaction by
                any customer or any transaction which defies the terms &
                conditions of using the Website, we at our sole discretion could
                cancel such orders. We will maintain a negative list of all
                fraudulent transactions and customers and would deny access to
                them or cancel any orders placed by them.
              </p>
            </li>
            <li>
              Return & Refunds
              <p>
                Blubirch endeavours to ensure that the products you buy from our
                Website are genuine, authentic, at the best prices possible. We
                also extend all the warranties, guarantees, wherever available,
                as entailed in the products sold by us to you, all this at a
                great price and with the best customer service in the industry.
              </p>
              <p>
                We do not entertain any returns for products purchased on the
                Website under any circumstances. However, if there is difference
                between lot received and manifest and such difference exceeds 4%
                of the lot value, then the Buyer shall be entitled to notify the
                Blubirch customer service at 1800-419-0431. In case the claim of
                the buyer is found to be genuine, the buyer shall be entitled to
                compensation equivalent to the value of shortfall determined by
                Blubirch.
              </p>
              <p>
                If Blubirch has suspicion or knowledge, that any of its members
                are involved in any activity that is intended to provide claims
                or information that is false, misleading or not genuine, then
                Blubirch may, while reserving its rights to initiate civil
                and/or criminal proceedings against such member, also at its
                sole discretion suspend, block, restrict, cancel the user id of
                Registered User and such necessary action against Guest User of
                such member and/or disqualify that member and any related
                members from availing protection through this program.
              </p>
              <p>
                Blubirch reserves its right to initiate civil and/or criminal
                proceedings against a user who, files an invalid and/or false
                claim or provides false, incomplete, or misleading information.
                In addition to the legal proceedings as aforesaid, Blubirch may
                at its sole discretion suspend, block, restrict, cancel the user
                id [and its related user ids] of such user and/or disqualify
                that member and any related members from availing protection
                through this program.
              </p>
            </li>
            <li>
              Breach
              <p>
                Without limiting other remedies, Blubirch may limit your
                activity, immediately remove your information, warn other Users
                of your actions, temporarily / indefinitely suspend or terminate
                or block your membership, and/or refuse to provide you with
                access to the Website in the event, but not limited to:
              </p>
              <ol>
                <li>
                  If you breach the User Agreement or Privacy Policy or the
                  documents, agreements rules, policies, terms and conditions as
                  incorporated herein by reference;
                </li>
                <li>
                  If Blubirch is unable to verify or authenticate any
                  information provided by you; or
                </li>
                <li>
                  If it is believed that your actions may cause legal liability
                  for other Users or Blubirch. We may at any time at our sole
                  discretion reinstate suspended Users. A User that has been
                  suspended or blocked may not register or attempt to register
                  with Blubirch or use the Website in any manner whatsoever
                  until such time that such User is reinstated by Blubirch.
                  Notwithstanding the foregoing, if you breach the User
                  Agreement or any other documents it incorporates by reference,
                  Blubirch reserves the right to take strict legal action
                  against you.
                </li>
              </ol>
            </li>
            <li>
              No Warranty
              <p>
                Blubirch provides the Website "as is" and without any warranty
                or condition, express, implied or statutory and specifically
                disclaim any implied warranties of title, merchantability,
                fitness for a particular purpose and non-infringement. You
                expressly agree that your use of the Website is at your own
                risk.
              </p>
            </li>
            <li>
              Limitation of Liability
              <ol>
                <li>
                  In no event shall Blubirch or its affiliates be liable for any
                  direct, indirect, incidental, special, incidental,
                  consequential or exemplary damages, including but not limited
                  to, damages for loss of profits, goodwill, use, data or other
                  intangible losses arising out of or in connection with the
                  Website, its services or this agreement (however arising,
                  including negligence).
                </li>
                <li>
                  Blubirch's liability in any circumstance is limited to the
                  amount of purchase consideration, if any, paid by you to
                  Blubirch during the relevant transaction. Blubirch makes no
                  representations or warranties about the accuracy, reliability,
                  completeness, and/or timeliness of any content, information,
                  software, text, graphics, links or communications provided on
                  or through the use of the Website or that the operation of the
                  Website will be error free and/or uninterrupted. Consequently,
                  Blubirch assumes no liability whatsoever for any monetary or
                  other damage suffered by you on account of: (i) the delay,
                  failure, interruption, or corruption of any data or other
                  information transmitted in connection with use of the Website;
                  (ii) Any interruption or errors in the operation of the
                  Website.
                </li>
              </ol>
            </li>
            <li>
              Intellectual Property
              <p>
                Blubirch expressly reserves all intellectual property rights in
                all text, programs, products, processes, technology, images,
                content and other materials which appear on the Website. Access
                to or use of the Website does not confer and should not be
                considered as conferring upon anyone any license to Blubirch’s
                intellectual property rights. All rights, including copyright,
                in and to the Website are owned by or licensed to Blubirch. Any
                use of the Website or its contents, including copying or storing
                it or them in whole or part is prohibited without the permission
                of Blubirch.
              </p>
            </li>
            <li>
              Indemnity
              <p>
                You agree to indemnify and hold harmless Blubirch and its
                shareholders, officers, directors, agents, and employees, from
                any losses, liabilities, damages, costs and expenses, claims or
                demand, or actions including reasonable attorneys' fees asserted
                against or incurred by Blubirch, made by any third party arising
                out of your breach or non-performance of terms of the User
                Agreement, Blubirch’s Rules and Policies, or the documents they
                incorporate by reference, or your violation of any law, rules or
                regulations or the rights of a third party.
              </p>
            </li>
            <li>
              Privacy
              <ol>
                <li>
                  We do not sell or rent your personal information to third
                  parties for their marketing purposes without your explicit
                  consent and we only use your information as described in the
                  Privacy Policy. We view protection of users' privacy as a very
                  important community principle. We understand clearly that you
                  and Your Personal Information is one of our most important
                  assets. We store and process Your Information on computers
                  located in Noida at the data centres that are protected by
                  physical as well as reasonable technological security measures
                  and procedures in accordance with Information Technology Act
                  2000 and rules there under. If you object to your Information
                  being transferred or used in this way please do not use the
                  Website.
                </li>
                <li>
                  Your Consent: By using the Site and/ or by providing Your
                  Information, you consent to the collection and use of the
                  information you disclose on the Website by Blubirch in
                  accordance with Blubirch Privacy Policy. You agree and
                  acknowledge that you have read and fully understood the
                  Privacy Policy and the terms and contents of such Privacy
                  Policy as mentioned in the Website is acceptable to you.
                </li>
              </ol>
            </li>
            <li>
              General
              <ol>
                <li>
                  None of the provisions of the User Agreement shall be deemed
                  to constitute a partnership or agency between you and Blubirch
                  and you shall have no authority to bind Blubirch in any manner
                  whatsoever.
                </li>
                <li>
                  Severability: If any clause of the User Agreement shall be
                  deemed invalid, void or for any reason unenforceable, such
                  clause shall be deemed severable and shall not affect the
                  validity and enforceability of the remaining clauses of the
                  User Agreement.
                </li>
                <li>
                  Entire Agreement: This Agreement sets forth the entire
                  understanding and agreement between you and Blubirch with
                  respect to the subject matter hereof.
                </li>
                <li>
                  Assignment: You cannot assign or otherwise transfer this User
                  Agreement, or any rights granted hereunder to any third party.
                  Blubirch may transfer its rights and obligations (also known
                  as "assign") under this Agreement without your prior express
                  consent, provided that Blubirch assigns the Agreement on the
                  same terms or terms that are no less advantageous to you.
                </li>
                <li>
                  Disputes Resolutions Generally, transactions are conducted
                  smoothly on Blubirch. However there may be some cases where
                  the Buyers may face issues. At Blubirch, we have a Dispute
                  Resolution process in order to resolve disputes. It is
                  important that before a Buyer raises a dispute, they should
                  attempt to resolve the issue amicably.Whenever there is a
                  disagreement relating to any transaction, the users can write
                  to our Grievance Officer at grievances@blubirch.com in order
                  to raise a dispute. Once a dispute is raised, Blubirch shall
                  try to resolve the matter amicably.
                </li>
              </ol>
            </li>
            <li>
              Arbitration
              <p>
                If any dispute arises between you and Blubirch during your use
                of the Website or thereafter, in connection with the validity,
                interpretation, implementation or alleged breach of any
                provision of the User Agreement, or the documents they
                incorporate by reference, the dispute shall be referred to a
                sole Arbitrator who shall be an independent and neutral third
                party identified by Blubirch. The place of arbitration shall be
                Bengaluru, Karnataka, India. The arbitration proceedings shall
                be in the English Language and shall be governed by Arbitration
                & Conciliation Act, 1996.
              </p>
            </li>
            <li>
              Governing Law
              <p>
                The User Agreement or the documents they incorporates by
                reference shall be governed and construed in accordance with the
                laws of India. Subject to Clause 16 above, all disputes arising
                under this Agreement between you and Blubirch shall be subject
                to the exclusive jurisdiction of courts at Bengaluru, Karnataka,
                India.
              </p>
            </li>
          </ol>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
