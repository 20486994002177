import { computed } from "vue";
import { axiosInstance, scbAxiosInstance } from "../../services";
import checkImage from "@/../assets/images/tick_green.png";
import { useAuth } from "../../stores";
import { isMobile } from "../../misc/index";
import { testimonials } from "../../static/index";

export default {
  data() {
    return {
      services: [],
      loading: true
    };
  },
  setup() {
    return {checkImage};
  },
  mounted() {
    this.getMarketPlace();
  },

  methods: {
    handleRedirect(link, bidType) {
      this.$router.push(link);
    },

    async getMarketPlace() {
      this.loading = true;
      let url1 = `/api/organizations/fetch_marketplace`;
      let url2 = "/api/external_service/get_marketplaces";
      let request1 = axiosInstance.get(url1).then(res => res).catch(err => err?.response);
      let request2 = scbAxiosInstance.get(url2).then(res => res).catch(err => err?.response);
      let [marketplace, scbMarketplace] = await Promise.all([
        request1,
        request2,
      ]);

      this.loading = false;

      let scbOpenMarketPlace = scbMarketplace?.data?.external_service?.map(
        (item) => {
          return {
            ...item,
            card_url: `/${item.card_url.split("/")[3]}/products`,
            type: "Open",
          };
        }
      ) || [];
      const remarkingLots = marketplace?.data?.marketplaces || [];
      var combinedResult = [
        ...remarkingLots,
        ...scbOpenMarketPlace,
      ];
      combinedResult = combinedResult.filter(rec => rec?.id);
      this.services = combinedResult.reduce((acc, item) => {
        const existingItem = acc.find(
          (element) =>
            element.name === item.name ||
            element.name.split(" ")[0] === item.name.split(" ")[0] ||
            element.name.split(".Ltd.Pvd")[0] === item.name.split(" ")[0]
        );

        if (existingItem) {
          existingItem.image = item.image_url;
          existingItem.lot_count += item.active_lots;
        } else {
          acc.push({
            ...item,
            name: item.name,
            lot_count: item.active_lots,
            link: item.card_url,
            bidType: item?.type || "Hybrid",
            image: item.image_url,
          });
        }
        return acc.splice(0, 3);
      }, []);
    },
  },
};
